import React from "react";
import Layout from "../components/Layout";
import axios from 'axios';
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import aviator from "../images/aviator.png"
const Results = () => {
    const [data, setData] = useState();
    const getResults = async () => {
        try {
          const response = await axios.get('https://app.oreidasvelasaltas.store/gameData/resultados');
          const data = response.data;
          const listData = [];
      
          Object.keys(data).forEach(listName => {
            const item = {
              listname: listName,
              greens: data[listName].greens,
              reds: data[listName].reds
            };
      
            listData.push(item);
          });
      
          return listData;
        } catch (error) {
          console.log(error);
        }
      };
      
      useEffect(() => {
        async function fetchData() {
          const result = await getResults();
          setData(result);
        }
      
        fetchData();
      }, []);

    if(!data) {
        return <div>Loading...</div>;
    }
    return(
        <Layout>
            <div className="text-white d-flex justify-content-center align-items-center" style={{display:'flex', flexDirection:'column'}}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mt-3 mb-3 rounded text-white">
                    <h4>RESULTADOS</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Table responsive bordered className="custom-table">
                      <thead className="text-white table-header">
                        <tr>
                          <th className="col-8 rounded-header">Jogo</th>
                          <th className="col-4 rounded-headerplacar">Placar</th>
                        </tr>
                      </thead>
                      <tbody className="text-white body-table">
                        {data.map((item) => (
                          <tr key={item.listname} className='mt-1 mb-1 text-white'>
                            <td className="row col-12 rounded">
                              <img className="" src={aviator} style={{maxWidth:'35%'}}/>
                              <div className="col-7">
                                <h5>Aviator 15X</h5>
                                <small>Spribe</small>
                              </div>
                            </td>
                            <td className="col-4"><span className="text-success">{item.greens}</span> X <span className="text-danger">{item.reds}</span></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
        </Layout>
    )
}

export default Results;