import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import BarLoader from 'react-spinners/BarLoader';
import SyncLoader from 'react-spinners/SyncLoader'
import ClipLoader from 'react-spinners/ClipLoader';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBomb, faInfoCircle, faRobot } from '@fortawesome/free-solid-svg-icons'
import { Card, Row, Col } from "react-bootstrap/";

const externalLink = "https://bet7k.com/casino/spribe-aviator"

const AviatorRoom = () => {
    const [loader, setLoader] = useState(false);
    const [messages, setMessages] = useState([]);
    const [minas, setMinas] = useState('');
    const [validoAte, setValidoAte] = useState('');
    const [tentativas, setTentativas] = useState('');
    const [oldMessages, setOldMessages] = useState([]);
    const [blocksQuadrados, setBlocksQuadrados] = useState();
    const [blocksEstrelas, setBlocksEstrelas] = useState();
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    }
    const handleExecute = () => {

    }
    useEffect(() => {
        
        const socket = new WebSocket('ws://gelatinous-zircon-moonflower.glitch.me:8765')
        // const socket = new WebSocket('ws://localhost:8765'); // Substitua pelo seu endpoint WebSocket
    
        socket.onopen = () => {
          console.log('Conexão WebSocket estabelecida.');
        };
    
        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          // Aqui você pode processar a mensagem recebida e atualizar o estado do componente
          setMessages(message);
          setOldMessages(message)
        };
    
        // socket.onclose = () => {
        //   console.log('Conexão WebSocket fechada.');
        // };
    
        // return () => {
        //   socket.close(); // Feche a conexão WebSocket ao desmontar o componente
        // };
      }, [messages]);

      useEffect(() => {
        console.log('messages',messages)
        console.log('oldMessages', oldMessages)
      },[messages === oldMessages ?'a' : messages])


    //   const quadradinhos = formatarQuadradinhos(messages[0]);

      useEffect(() => {    
        const formatarLista = (messages) => {
            if (messages){
                const regexMinas = /Minas: (\d+)/;
                const regexValidoAte = /Valido até as (\d+:\d+)/;
                const regexTentativas = /Nº de tentativas: (\d+)/;

                const minas = messages.match(regexMinas)[1];
                const validoAte = messages.match(regexValidoAte)[1];
                const tentativas = messages.match(regexTentativas)[1];

                setMinas(minas);
                setValidoAte(validoAte);
                setTentativas(tentativas)
        };
        
        }
        
        const formatarQuadradinhos = (messages) => {
            if (messages) {
                const linhas = messages.split('\n');
                if (linhas) {
                    const quadradinhosAzuis = [];
                    const estrelas = [];

                    for (let i = linhas.length - 5; i < linhas.length; i++) {
                        const linhaQuadradinhos = linhas[i].split(' ');

                        const quadradinhos = [];
                        const estrelasLinha = [];

                        for (let j = 0; j < linhaQuadradinhos.length; j++) {
                            quadradinhos.push(linhaQuadradinhos[j]);
                        }

                        quadradinhosAzuis.push(quadradinhos);
                        estrelas.push(estrelasLinha);
                    }

                    setBlocksQuadrados(quadradinhosAzuis);
                    setBlocksEstrelas(estrelas);
                }
            }
        };

        formatarLista(messages[0]);
        formatarQuadradinhos(messages[0]);
        
        setLoader(true)
    }, [messages]);

    const renderTable = () => {
        
        const renderQuadradinhos = () => {
          if (blocksQuadrados) {
            return blocksQuadrados.map((linha, index) => (
              <div key={index}>
                {linha.map((quadradinho, index) => (
                  <span key={index}>{quadradinho} </span>
                ))}
              </div>
            ));
          }
        };
      
        return (
          <div>
            {renderQuadradinhos()}
          </div>
        );
      };
    return(
        <Layout>
            <div>
                <Card 
                    className="m-2"
                    style={{background: 'linear-gradient(to bottom, #1C1C1C, #363636)'}}
                >
                    <Col className="m-2 border rounded border-dark text-white">
                        <small>
                            ÚLTIMO SINAL
                        </small>
                        {!minas?(<h3 className="piscante">Aguardando</h3>):<h3 className="text-success">DEU GREEN</h3>}
                    </Col>
                    <Row className="text-white m-2">
                        <Col className="m-1 border rounded border-dark">
                            <small style={{color:'yellow'}}>
                                Minas no Jogo:
                            </small>
                            {'\n'}
                            <p>
                                {minas?
                                    minas:(
                                    <SyncLoader
                                    className="m-3"
                                    cssOverride={override}
                                    color="white"
                                    size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                    />
                                )}
                            </p>
                        </Col>
                        <Col className="m-1 border rounded border-dark">
                            <small style={{color:'yellow'}}>
                                Nº Tentativas:
                            </small>
                            {'\n'}
                            {tentativas?
                            tentativas:
                            (
                                <SyncLoader
                                className="m-3"
                                cssOverride={override}
                                color="white"
                                size={5}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                />
                            )}
                        </Col>
                        <Col className="m-1 border rounded border-dark">
                            <small style={{color:'yellow'}}>
                                Válido <br/> até:
                                <br/>
                            </small>
                            {'\n'}
                            {validoAte?
                            validoAte:
                            (
                                <SyncLoader
                                className="m-3"
                                cssOverride={override}
                                color="white"
                                size={5}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                />
                            )}
                        </Col>
                        <Card className="robot bg-dark text-white d-flex align-items-center justify-content-center" style={{ minHeight: '20vh' }}>
                            {blocksQuadrados ? (
                                renderTable()
                                ): (
                                    <>
                                    <FontAwesomeIcon icon={faRobot} size={"3x"} color="white" />
                                    <small>
                                        Identificando zona de calor das bombas
                                    </small>
                                    <BarLoader className="m-3"
                                    cssOverride={override} 
                                    color="white"
                                    size={15} 
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                    />
                                    
                                </>
                            )}
                        </Card>
                    </Row>
                </Card>
                <iframe
                    className="rounded border border-2 border-white"
                    title="Site incorporado"
                    src="https://www.acertosbet.com/jogar/3692"
                    width="90%"
                    height="600px"
                    />
                <div className="mt-4 col-12 row d-flex align-items-center">
                    <small className="col-7 text-white">
                        Se o jogo não abrir acima, clique ao lado!
                    </small>
                    <Button className="col-5 " href={externalLink} target="_blank">
                        Acessar Mines
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export default AviatorRoom;