import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col";
import aviator from "../images/aviator.png";
import mines from "../images/mines.jpeg";
import footballx from "../images/footballx.png";
import footballStudio from "../images/footballstudio.jpg";
import penaltyshootout from "../images/penaltyshootout.jpeg";
import fortunemouse from "../images/fortunemouse.jpg";
import fortunerabbit from "../images/fortunerabbit.png";
import roleta from "../images/roleta.png"
import fortune from "../images/fortune.png"
import bacbo from "../images/bacbo.png";
import jetx from "../images/jetx.png";
import immersive from "../images/immersive.jpg";
import spaceman from "../images/spaceman.png";
import { InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import imageheader2 from "../images/header.gif";

const Game = () => {
    const [messages, setMessages] = useState([]);
    const [oldMessages, setOldMessages] = useState([]);

    const images = [
        {
            image:mines,
            href:'/mines',
            width: '5.8rem',
            height:'5.8rem',
            //:'Mines',
            available: messages[13] === 'expirado' ? false : true,
        },
        {
            image:roleta,
            href:'/roleta',
            width: '5.8rem',
            height:'5.8rem',
            //:'Roleta',
            available: messages[0] === 'expirado' ? false : true,
        },
        {
            image:fortune,
            href:'/fortune',
            width: '5.8rem',
            height:'5.8rem',
            //:'Fortune Tiger',
            available: messages[11] === 'expirado' ? false : true,
        },
        {
            image:footballStudio,
            href:'/footballstudio',
            width: '5.8rem',
            height:'5.8rem',
            available: messages[5] === 'expirado' ? false : true,
            //:'Football Penalty'
        },
        {
            image:penaltyshootout,
            href:'/penaltyshootout',
            width: '5.8rem',
            height:'5.8rem',
            // filter:'grayscale(10%) opacity(0.4)',
            available: messages[6] === 'expirado' ? false : true,
            //:'Football Penalty'
        },
        {
            image:fortunerabbit,
            href:'/fortunerabbit',
            width: '5.8rem',
            height:'5.8rem',
            // filter:'grayscale(10%) opacity(0.4)',
            available: messages[9] === 'expirado' ? false : true,
        },
        {
            image:immersive,
            href:'/immersive',
            width: '5.8rem',
            height:'5.8rem',
            // filter:'grayscale(10%) opacity(0.4)',
            available:messages[12] === 'expirado' ? false : true,
        },
        {
            image:fortunemouse,
            href:'/fortunemouse',
            width: '5.8rem',
            height:'5.8rem',
            // filter:'grayscale(10%) opacity(0.4)',
            available: messages[8] === 'expirado' ? false : true,
        },
        {
            image:spaceman,
            // href:'/spaceman',
            width: '5.8rem',
            height:'5.8rem',
            filter:'grayscale(10%) opacity(0.4)',
            // available: messages[14] === 'expirado' ? false : true,
            available:'breve'
            //:'Spaceman'
        },
        {
            image:footballx,
            // href:'/footballx',
            width: '5.8rem',
            height:'5.8rem',
            filter:'grayscale(10%) opacity(0.4)',
            // available: messages[7] === 'expirado' ? false : true,
            available:'breve'
            //:'Bacbo'
        },
        {
            image:bacbo,
            // href:'/bacbo',
            width: '5.8rem',
            height:'5.8rem',
            filter:'grayscale(10%) opacity(0.4)',
            // available: messages[2] === 'expirado' ? false : true,
            available: 'breve'
            //:'Bacbo'
        },
        {
            image: aviator,
            // href:'/aviator',
            width: '5.8rem',
            height:'5.8rem',
            filter:'grayscale(10%) opacity(0.4)',
            available:'breve',
            //:'Aviator',
        },
        {
            image:jetx,
            // href:'/penalty',
            width: '5.8rem',
            height:'5.8rem',
            filter:'grayscale(10%) opacity(0.4)',
            available:'breve',
            //:'JetX'
        },
        
    ];

    useEffect(() => {
        const socket = new WebSocket('wss://ws-connect-signals-3fc47d5b7c5f.herokuapp.com/');
        socket.onopen = () => {
            
        };
        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            setMessages(message);
        };
    },[messages]);

    // useEffect(() => {
    //     console.log('messages',messages)
    //   },[messages])

    return (
        <Layout>
            <div className="game-container text-white">
                <div className="mt-3 m-2" style={{width:'95%'}}>
                    <div 
                        className="mt-3 card color-none" 
                        style={{'background': 'linear-gradient(to bottom, #0a090c, #180e23)'}}
                    >
                        <img className="rounded" src={imageheader2} style={{width:'100%'}}/>
                    </div>
                </div>
                <div>
                    <center className="mt-1 mb-3">
                        <div style={{width:'95%'}}>
                            <InputGroup className=" mt-3 bg-dark mb-2 col-12 rounded">
                                <InputGroup.Text className="bg-dark text-white" style={{ border: 'none' }}>
                                    <FontAwesomeIcon icon={faPlay} /> {'  '}
                                </InputGroup.Text>
                                <InputGroup.Text className="col-10 rounded bg-dark text-white" style={{border:'none'}}>
                                    Jogos Disponíveis
                                </InputGroup.Text>
                                <Row className="col-12">
                                    <Col className="col-6 d-flex align-items-center">
                                    <div className="rounded-circle bg-warning me-1 bolinhawarning" style={{ width: '0.5rem', height: '0.5rem' }}></div>
                                        <small>Possível Entrada</small>
                                    </Col>
                                    <Col className="col-6 d-flex align-items-center">
                                    <div className="rounded-circle bg-success me-1 bolinhasuccess" style={{ width: '0.5rem', height: '0.5rem' }}></div>
                                        <small>Entrada Confirmada</small>
                                    </Col>
                                </Row>
                            </InputGroup>
                            <div>    
                                <Row className="col-12">
                                {images.map((obj) => (
                                    <Col className='mt-3 mb-3' style={{margin:'0px'}}>
                                        <a href={obj.href}>
                                        <img
                                        className={`col-12 rounded border border-3 ${
                                            obj.available === true ? 'border-success blinksuccess' : (obj.available ==='breve' ? 'border-white' : 'border-warning blinkwarning')
                                        }`}
                                        src={obj.image}
                                        style={{ width: obj.width, height: obj.height, filter: obj.filter}}
                                        />
                                        </a>
                                        <small className="text-white text-decoration-none">{obj.description}</small>
                                    </Col>
                                ))}
                                </Row>
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        </Layout>
    )
}

export default Game;