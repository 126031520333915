import React, { useState } from "react";
import Layout from "../components/Layout";
import letter from "../images/letter_logo.png"
import { AppProvider } from "../context/AppContext";
import exclamation from "../images/exclamation.png";
import { Modal, Button } from 'react-bootstrap';


const PlayPix = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);

    const handleLoginClick = () => {
        setShowLogin(true);
        setShowRegister(false);
    };

    const handleRegisterClick = () => {
        setShowLogin(false);
        setShowRegister(true);
    };

    const handleClose = () => {
        setShowLogin(false);
        setShowRegister(false);
    };
    return(
        <>
        <AppProvider>
            <Layout>
            <div className="game-container col-12 text-white" style={{minHeight:'100vh', width:'100%'}}>
                <center 
                    className="mt-5" 
                    style={{
                        width:'95%'
                    }}
                >
                    <h4 className="text-light mb-4" style={{ fontFamily: 'Inter, sans-serif' }}>Para melhor experiência usando o 
                        <img src={letter} style={{width:'10rem'}} />
                    {' '}<br/>entre na casa de apostas</h4>
                    <Button
                        className="col-9 mt-2"
                        onClick={handleLoginClick}
                        type="submit"
                        style={{ height: '3.2rem', border: '0.2rem solid #05d9e8', backgroundColor: 'black' }}
                    >
                        Fazer login
                    </Button>
                    <Button
                        className="col-9 mt-2"
                        onClick={handleRegisterClick}
                        type="submit"
                        style={{ height: '3.2rem', border: '0.2rem solid #05d9e8', backgroundColor: 'black' }}
                    >
                        Cadastrar-se
                    </Button>

                    {showRegister && (
                        <>
                        <p
                        className="col-5 mt-4"
                        >
                            Cadastre-se
                        </p>
                        <iframe
                            className="mt-1 rounded border border-2 border-black"
                            title="Site incorporado"
                            src="https://www.acertosbet.com/minha-conta/cadastro?clickid=ACR787e69709746f2d298b028ad6376f161&utm_source="
                            width="90%"
                            height="500px"
                        />
                        <div style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
                            <img src={exclamation} />
                            <h4>Fique tranquilo</h4>
                            <small>Você está em um ambiente seguro</small>
                            <br />
                            <small className="mb-3">Seus dados de acesso são criptografados e não são armazenados ou compartilhados!</small>
                        </div>
                        </>
                    )}

                    {showLogin && (
                        <>
                        <p
                        className="col-5 mt-4"
                        >
                            Login
                        </p>
                        <iframe
                            className="mt-1 rounded border border-2 border-black"
                            title="Site incorporado"
                            src="https://www.acertosbet.com/minha-conta/login"
                            width="90%"
                            height="500px"
                        />
                        <div style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
                            <img src={exclamation} />
                            <h4>Fique tranquilo</h4>
                            <small>Você está em um ambiente seguro</small>
                            <br />
                            <small className="mb-3">Seus dados de acesso são criptografados e não são armazenados ou compartilhados!</small>
                        </div>
                        </>
                    )}
                </center>
            </div>
            </Layout>
        </AppProvider>
        </>
    );
}

export default PlayPix;