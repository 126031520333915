import React, {useState} from "react";
import Layout from "../components/Layout";
import iaHome from "../images/ia.gif"
import inputGenerate from "../images/inputgenerate.jpeg"
import robotAutomate from "../images/robotAutomate.jpeg"
import stadium from "../images/stadium.jpeg"
import Row from "react-bootstrap/Row"
import toast from 'react-hot-toast';
import { AppProvider } from "../context/AppContext";
import googleplay from "../images/google-play.png";
import appstore from "../images/app-store.png";
import { Modal, Button } from 'react-bootstrap';
import android from "../images/android.png"
import ios from "../images/ios.png";
import shareIcon from "../images/share-icon.png";
import addIcon from "../images/add-icon.png";
import shareIconAndroid from "../images/share-icon-android.png";



const Home = () => {
    
    const notify = () => toast.error('Recurso disponível em breve.', {
    position: "bottom-center"
    });
    const [showModal, setShowModal] = useState(false);
    const [iosShowModal, setIosShowModal] = useState(false);

    const handleModalOpen = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const iosHandleModalOpen = () => {
        setIosShowModal(true);
    };

    const iosHandleModalClose = () => {
        setIosShowModal(false);
    };
      
    return(
        <>
        <AppProvider>
            <Layout>
                <div className="game-container col-12 text-white" style={{minHeight:'100vh', width:'100%'}}>
                    <center 
                        className="mt-5 " 
                        style={{
                            minHeight:'45vh',
                        }}
                    >
                        <h6 className="text-light" style={{ fontFamily: 'Inter, sans-serif', fontWeight:'bolder' }}>Escolha a melhor forma para <span style={{color:'#05d9e8'}}>lucrar agora!</span>🤑🎰</h6>
                        <Row className="col-12 mt-4">
                            <div className="col-6" style={{ position: 'relative' }}>
                            <a href="/games" className="text-white">
                                <div className="gradient-border">
                                    <img className="col-12 rounded" src={iaHome} style={{ height: '15vh', objectFit: 'cover', 
                                    border:'3px solid yellow' 
                                    }} />
                                </div>
                                <h7 className="col-10 rounded" style={{ position: 'absolute', top: '70%', left: '8%', background: 'rgba(24, 14, 35, 0.6)'}}>Inteligência artificial</h7>
                            </a>
                            </div>
                            <div className="col-6" style={{ 
                                position: 'relative', 
                                filter:'grayscale(10%) opacity(0.4)'
                            }}
                            >
                            <img onClick={notify} className="col-12 rounded" src={stadium} style={{ height: '15vh', objectFit: 'cover', border:'3px solid yellow' }} />
                                <h6 className="col-10 rounded" style={{ position: 'absolute', top: '75%', left: '8%', background: 'rgba(24, 14, 35, 0.6)'}}>Robô automático</h6>
                            </div>
                        </Row>
                        <Row className="col-12 mt-4">
                            <div className="col-6" style={{
                                position: 'relative', 
                                // filter:'grayscale(100%) opacity(0.5)' 
                                filter:'grayscale(10%) opacity(0.4)'
                                }}
                            >
                            <img onClick={notify} className="col-12 rounded" src={robotAutomate} style={{ height: '15vh', objectFit: 'cover', border:'3px solid yellow' }} />
                                <h6 className="col-10 rounded" style={{ position: 'absolute', top: '75%', left: '8%', background: 'rgba(24, 14, 35, 0.6)'}}>Roleta ao vivo</h6>
                            </div>
                            <div className="col-6" style={{ 
                                position: 'relative', 
                                // filter:'grayscale(100%) opacity(0.5)' 
                                filter:'grayscale(10%) opacity(0.4)'
                                }}
                            >
                                <img onClick={notify} className="col-12 rounded" src={inputGenerate} style={{ height: '15vh', objectFit: 'cover', border:'3px solid yellow' }} />
                                <h6 className="col-10 rounded" style={{ 
                                    position: 'absolute',
                                    top: '75%',
                                    left: '8%', 
                                    background: 'rgba(24, 14, 35, 0.6)'
                                    }}
                                >
                                    Apostas esportivas
                                </h6>
                            
                            </div>
                        </Row>
                    </center>
                    <div style={{ marginTop: '5%' }}>
                        <center>
                            <h7 className="flex-wrap" style={{ fontWeight: 'bolder' }}>Instale nosso app</h7>
                        </center>
                        <center
                            style={{
                                marginRight:'1rem'
                            }}
                        >

                            <img
                            className="rounded mt-3 m-3"
                            src={googleplay}
                            style={{ 
                                width: '9rem', 
                                height:'2.7rem', 
                                cursor: 'pointer',
                            }}
                            onClick={handleModalOpen}
                            />
                            <img
                                className="rounded"
                                src={appstore}
                                style={{ width: '9rem',marginTop:'' , height:'2.7rem', cursor: 'pointer' }}
                                onClick={iosHandleModalOpen}
                            />
                        </center>
                        <Modal
                        show={showModal}
                        onHide={handleModalClose}
                        centered
                        style={{ maxWidth: '90%', marginLeft: '5%' }}
                        >
                            <Modal.Header
                                style={{ 
                                background: 'black', 
                                borderTop: '2px solid rgba(252, 248, 12, 0.932)',
                                borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
                                borderRight: '2px solid rgba(252, 248, 12, 0.932)',
                                borderBottom: '0 solid black',
                                }}
                                closeButton
                            >
                                <Modal.Title style={{ color: 'white', marginLeft:'2rem' }}>
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div>
                                    <img src={android} style={{ width: '10rem' }} />
                                    <h5 className="col-12 mb-0 d-flex text-center justify-content-center" style={{ color:'#05d9e8'}}>
                                        Instale o nosso app
                                    </h5>
                                    </div>
                                </div>
                                <small 
                                className="text-white d-flex justify-content-center text-center"
                                style={{fontSize:'0.8rem', marginTop:'0.4rem'}}
                                >
                                    Clique na janela que aparecerá no rodapé da página ou clique em adicione o App à tela inicial do seu smartphone
                                </small>
                                <h6 
                                className="d-flex justify-content-center text-center" 
                                style={{ color:'#05d9e8', marginTop:'1rem'}}
                                >
                                    Android (Google Chrome)
                                </h6>
                                <div 
                                className="rounded col-12 d-flex justify-content-center text-center"
                                style={{backgroundColor:'green', color:'white', paddingTop:'0.3rem', paddingBottom:'0.3rem'}}
                                >
                                    <small className="col-10" style={{fontSize:'1rem', marginTop:'0.3rem'}}>INSTALAR APLICATIVO</small>
                                    <img src={shareIconAndroid} style={{width:'1.8rem', height:'1.8rem'}}/>
                                </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body 
                                style={{ 
                                background: 'black',
                                borderTop: '2px solid black', 
                                borderBottom: '2px solid rgba(252, 248, 12, 0.932)',
                                borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
                                borderRight: '2px solid rgba(252, 248, 12, 0.932)',
                                }}
                            >
                            </Modal.Body>
                        </Modal>

                        <Modal
                        show={iosShowModal}
                        onHide={iosHandleModalClose}
                        centered
                        style={{ maxWidth: '90%', marginLeft: '5%'}}
                        >
                            <Modal.Header
                                style={{ 
                                background: 'black', 
                                borderTop: '2px solid rgba(252, 248, 12, 0.932)',
                                borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
                                borderRight: '2px solid rgba(252, 248, 12, 0.932)',
                                borderBottom: '0 solid black',
                                }}
                                closeButton
                            >
                                <Modal.Title style={{ color: 'white' }}>
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div>
                                    <img src={ios} style={{ width: '5rem', marginLeft:'10%' }} />
                                    </div>
                                    <div>
                                    </div>
                                    <h5 className="col-9" style={{marginLeft:'10%', color:'#05d9e8'}}>
                                        Instale o nosso app
                                    </h5>
                                    <small className="col-10 justify-content-center text-center" style={{marginLeft:'10%', fontSize:'0.9rem'}}>Siga as etapas abaixo e instale o nosso app em seu dispositivo</small>
                                    <small style={{color:'#05d9e8'}}>iOS (Safari)</small>
                                </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body 
                                style={{ 
                                background: 'black',
                                borderTop: '2px solid black', 
                                borderBottom: '2px solid rgba(252, 248, 12, 0.932)',
                                borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
                                borderRight: '2px solid rgba(252, 248, 12, 0.932)',
                                }}
                            >
                                <div className="col-12 row" style={{marginLeft:'3%'}}>
                                    <div className="col-2 rounded" style={{border:'2px solid #05d9e8'}}>
                                        <h3 
                                            style={{ 
                                            marginTop:'4px', 
                                            marginLeft:'-4px', 
                                            marginRight:'0px',
                                            color:'#05d9e8'
                                            }}
                                        >
                                        01
                                        </h3>
                                    </div>
                                    <small className="col-7 text-white" style={{fontSize:'0.7rem'}}>Clique no símbolo de compartilhar</small>
                                    <div className="col-2">
                                        <img src={shareIcon} style={{marginRight:'0.1rem' ,width:'2rem'}} />
                                    </div>
                                </div>
                                <div className="col-12 row mt-2" style={{marginLeft:'3%'}}>
                                    <div className="col-2 rounded" style={{border:'2px solid #05d9e8'}}>
                                        <h3 
                                            style={{ 
                                            marginTop:'4px', 
                                            marginLeft:'-4px', 
                                            marginRight:'2px',
                                            color:'#05d9e8'
                                            }}
                                        >
                                        02
                                        </h3>
                                    </div>
                                    <small className="col-7 text-white" style={{fontSize:'0.7rem'}}>Procure o botão "Tela de início"</small>
                                    <div className="col-2">
                                        <img src={addIcon} style={{marginLeft:'0.2rem' , width:'1.7rem'}} />
                                    </div>
                                </div>
                                <div className="col-12 row mt-2" style={{marginLeft:'3%'}}>
                                    <div className="col-2 rounded" style={{border:'2px solid #05d9e8'}}>
                                        <h3 
                                            style={{ 
                                            marginTop:'4px', 
                                            marginLeft:'-4px', 
                                            marginRight:'2px',
                                            color:'#05d9e8'
                                            }}
                                        >
                                        03
                                        </h3>
                                    </div>
                                    <small className="col-6 text-white" style={{fontSize:'0.7rem'}}>Adicione o app à tela de início</small>
                                    <div className="col-2" style={{}}>
                                        <small 
                                        className="rounded" 
                                        style={{
                                            marginLeft:'0.5rem',
                                            fontSize:'0.8rem',
                                            color:'#05d9e8', 
                                            borderTop:'0.1rem solid white', 
                                            paddingTop:'0.2rem', 
                                            paddingBottom:'0.3rem', 
                                            paddingLeft:'0.3rem', 
                                            paddingRight:'0.3rem', 
                                            backgroundColor:'white'
                                            }}>
                                                Adicionar
                                        </small>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        
                    </div>
                </div>
            </Layout>
        </AppProvider>
        </>
    );
}

export default Home;