export const logoutUser = () => {
    // Aqui, você pode realizar qualquer lógica necessária para fazer o logout no frontend.
    // Por exemplo, remover o token armazenado no localStorage ou em cookies.
    // Ou, se estiver usando Redux, você pode limpar os dados de autenticação no store.
  
    // Após realizar a lógica de logout, você pode retornar uma ação que sinaliza que o usuário foi desconectado.
    // Por exemplo:
    return {
      type: 'LOGOUT_USER',
    };
  };