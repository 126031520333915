import React from 'react';
import { Row, Col } from 'react-bootstrap';
import home from "../images/home.png";
import game from "../images/game.png";
import money from "../images/money-.png";
import stats from "../images/stats.png"
import academy from "../images/academy.png"
import toast, { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  const getCurrentPathname = () => {
    return window.location.pathname;
  };
  const notify = () => toast.error('Recurso disponível em breve.', {
    position: "top-center"
  })


  const buttons = [
    {
      title :'Início',
      icon: home,
      size: '2rem',
      href: '/home',
    },
    {
      title :'Bônus',
      icon: money,
      size: '2rem',
      href: '/bonus',
    },
    {
      title: 'games',
      icon: game,
      size: '4rem',
      href: ('/game'),
    },
    {
      title :'Academy',
      icon: academy,
      size: '2.3rem',
      href: '/academy',
    },
    {
      title :'Resultados',
      icon: stats,
      size: '2rem',
      href: '/results',
    },
  ];

  return (
    <div className="bg-black text-white fixed-bottom py-2">
      <Row className="justify-content-center">
        {buttons.map((button) => (
          <>
          
          <Col
            className={`col-2 rounded text-center`}
            key={button.href}
            style={{
              background:`${getCurrentPathname() === button.href ? 'black' : ''}`,
              border:`${getCurrentPathname() === button.href ? '0.3rem solid yellow' : ''}`,
              paddingTop:'0.5rem',
              paddingBottom:'0.5rem',
              opacity: `${getCurrentPathname() === button.href ? '1' : '0.2'}`,
            }}
          >
            <a
              onClick={button.href === '/academy' || button.href === '/results' || button.href === '/bonus'? notify : null}
              href={button.href === '/academy' || button.href === '/results' || button.href === '/bonus'? null : button.href}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                
              }}
            >
              <img
                src={button.icon}
                style={{
                  width:button.size,
                }}
                />
            </a>
          </Col>
              {/* <small>{button.title}</small>  */}
          </>
        ))}
      </Row>
    </div>
  );
};

export default Footer;
