import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Button } from "react-bootstrap";
import { Card, Row, Col } from "react-bootstrap/";
import Loader from "../images/loader.gif";
import arrowleft from "../images/arrow-left.png";


const MinesRoom = () => {
    const [loader, setLoader] = useState(false);
    const [messages, setMessages] = useState([]);
    const [minas, setMinas] = useState('');
    const [validoAte, setValidoAte] = useState('');
    const [tentativas, setTentativas] = useState('');
    const [oldMessages, setOldMessages] = useState([]);
    const [blocksQuadrados, setBlocksQuadrados] = useState();
    

    useEffect(() => {
        const socket = new WebSocket('wss://ws-connect-signals-3fc47d5b7c5f.herokuapp.com/');
        socket.onopen = () => {
          console.log('Conexão WebSocket estabelecida.');
        };
      
        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          setTimeout(() => {
            setMessages(message);
            setOldMessages(message);
          }, 5000);
        };
      }, [messages]);
      

      useEffect(() => {
        console.log('messages',messages);
      },[messages === oldMessages ?'a' : messages])


      useEffect(() => {    
        const formatarLista = (messages) => {
            if (messages){
                const regexMinas = /🎰: (\d+)/;
                const regexValidoAte = /⏱ Válido até: (\d+:\d+)/;
                const regexTentativas = /🕹:Tentativas: (\d+)/;

                const minasMatch = messages.match(regexMinas);
                const validoAteMatch = messages.match(regexValidoAte);
                const tentativasMatch = messages.match(regexTentativas);

                if (minasMatch) {
                setMinas(minasMatch[1]);
                }

                if (validoAteMatch) {
                setValidoAte(validoAteMatch[1]);
                }

                if (tentativasMatch) {
                const tentativasValue = parseInt(tentativasMatch[1]);
                setTentativas(tentativasValue.toString());
                }
        
            }
        
        }
        
        const formatarQuadradinhos = (message) => {
            if (message) {
                if (messages[13] !== "expirado"){

                    const linhas = message.split('\n');
                    if (linhas) {
                        const quadradinhosAzuis = [];
                        const estrelas = [];
    
                        for (let i = linhas.length - 10; i < linhas.length-5; i++) {
                            const linhaQuadradinhos = linhas[i].split(' ');
    
                            const quadradinhos = [];
                            const estrelasLinha = [];
    
                            for (let j = 0; j < linhaQuadradinhos.length; j++) {
                                quadradinhos.push(linhaQuadradinhos[j]);
                            }
    
                            quadradinhosAzuis.push(quadradinhos);
                            estrelas.push(estrelasLinha);
                        }
    
                        setBlocksQuadrados(quadradinhosAzuis);
                    }
                }
            }
        };

        formatarLista(messages[13]);

        if (messages[13] === "expirado"){
            setMinas(null)
            setTentativas(null)
            setValidoAte(null)
            setBlocksQuadrados(null)
        }else{
            formatarQuadradinhos(messages[13]);
        }
        setLoader(true)
    }, [messages]);

    const renderTable = () => {
        
        const renderQuadradinhos = () => {
          if (blocksQuadrados) {
            return blocksQuadrados.map((linha, index) => (
              <div key={index}>
                {linha.map((quadradinho, index) => (
                  <span key={index}>{quadradinho} </span>
                ))}
              </div>
            ));
          }
        };
      
        return (
          <div>
            {renderQuadradinhos()}
          </div>
        );
      };
    
    const renderContent = () => {
        setTimeout(() => {
            renderLoading()
          }, 5000); // 5 segundos de atraso
        return (
           <>
           {!minas ?
            <>
                <Card className="d-flex align-items-center justify-content-center bg-black">
                    <div 
                    className="col-lg-6 col-md-8 col-sm-10 col-3 offset-1 iframe-container d-flex justify-content-center align-items-center"
                    style={{
                        flexDirection:'column'
                    }}
                    >
                        <iframe
                        src={Loader}
                        allowFullScreen
                        style={{
                            width: '160%', 
                            height: '70%',
                            display:'absolute',
                            border: 'none',
                            pointerEvents: 'none',
                            overflow: 'hidden'
                        }}
                        scrolling="no"
                        />
                    </div>
                    <h5>
                        Sinal expirado
                    </h5>
                    <h5 className="piscante text-white">
                        Identificando jogada, aguarde...
                    </h5>
                </Card>
            </>
           :
            ''
           }
           {minas ?
                <Col>
                    <small>
                        Minas
                    </small>
                    <div className="m-1 border rounded border-light">
                        <h5 className="mt-1" style={{color:'yellow'}}>
                                {minas}
                        </h5>
                    </div>
                </Col>
                :
                null
            }
            {tentativas ?
                <Col>
                    <small>
                        Tentativas
                    </small>
                    <div className="m-1 border rounded border-light">
                        <h5 className="mt-1" style={{color:'yellow'}}>{tentativas}</h5>
                    </div>
                </Col>
                :
                null
            }
            {validoAte ?

                <Col>
                    <small>
                        Válido
                    </small>
                    <div className="m-1 border rounded border-light">
                        <h5 className="mt-1" style={{color:'yellow'}}>{validoAte}</h5>
                    </div>
                </Col>
                :
                null
            }
           </> 
        )
    }
    const renderLoading = () => {
        return (
            <>
                <div 
                className="col-lg-6 col-md-8 col-sm-10 col-3 offset-1 iframe-container d-flex justify-content-center align-items-center"
                style={{ 
                    // height: '100vh', 
                    // width: '100vw', 
                    flexDirection: 'column' 
                }}
                >
                    <iframe
                    src={Loader}
                    allowFullScreen
                    style={{
                        width: '190%', 
                        height: '70%',
                        display:'absolute',
                        border: 'none',
                        pointerEvents: 'none',
                        overflow: 'hidden'
                    }}
                    scrolling="no"
                    />
                </div>
                <h5 className="piscante">
                    Sinal expirado
                </h5>
                <h5 className="piscante">
                    Identificando jogada, aguarde...
                </h5>
            </>
        )
    }
    return(
        <Layout>
            <div className="game-container">
                <div className="back-button-container">
                    <a href="/games">
                        <img src={arrowleft} className="back-button" />
                    </a>
                </div>
                {/* <Card 
                    className="col-12"
                    style={{background:'black'}}
                >
                </Card> */}
                    <Row className="offset-1 col-10 text-white mt-1">
                        <Col>
                            {messages[3] === ("expirado" || undefined)?
                            null
                            :
                            renderContent()
                            }
                        </Col>
                        <Card className="mt-2 text-white d-flex align-items-center justify-content-center" style={{ 
                            minHeight: '10vh', 
                            background:'black'
                        }}>
                        {messages[3] === ("expirado" || undefined) ? (
                            renderLoading()
                        ) : (
                            renderTable()
                        )}
                        </Card>
                    </Row>
                <iframe
                    className="rounded border border-2 border-white"
                    title="Site incorporado"
                    src="https://www.acertosbet.com/jogar/3693"
                    width="85%"
                    height="600px"
                    />
            </div>
        </Layout>
    )
}

export default MinesRoom;