import React from "react";
import Layout from "../components/Layout";
const Academy = () => {
    return(
        <Layout>
            <div className="text-white d-flex align-items-center" style={{'background': 'linear-gradient(to bottom, #000, #27315c)', height:'100vh', width:'100vw', top:'0', display:'flex', flexDirection:'column'}}>
                <center>
                </center>
                    <div className="mt-3 mb-3 rounded" style={{background:'green', width:'30%'}}>
                        <h4>
                            APRENDA A LUCRAR
                        </h4>
                    </div>
                    <img className="rounded border border-3" src="https://app.universidadebet.com.br/wp-content/uploads/2023/05/capa-de-video.webp" style={{ maxWidth: '70%' }} />
                    <img className="rounded border border-3" src="https://app.universidadebet.com.br/wp-content/uploads/2023/05/capa-de-video.webp" style={{ maxWidth: '70%' }} />
                    
            </div>
        </Layout>
    )
}

export default Academy;