import React from "react";
import logo from "../images/money-app-logo.png";
import Button from "react-bootstrap/Button";
import Layout from "../components/Layout";
const Bonus = () => {
    return(
        <Layout>
            <div className="text-white d-flex align-items-center" style={{'background': 'linear-gradient(to bottom, #000, #27315c)', height:'100vh', width:'100vw', top:'0', display:'flex', flexDirection:'column'}}>
                    <div className="rounded mt-5 mb-3" style={{background:'green', padding:'0.2rem'}}>
                        <h4>
                            PEGUE SEU BÔNUS
                        </h4>
                    </div>
                    <img className="rounded border border-3" src="https://app.universidadebet.com.br/wp-content/uploads/2023/05/capa-de-video.webp" style={{ maxWidth: '70%' }} />
                    <h6 className="mt-3 col-6">
                        Se você já possui uma conta, crie uma nova com outro CPF para resgatar até R$300 em bônus.
                    </h6>
                    <Button className="col-8 mt-5" type="submit">
                        {"QUERO MEU BÔNUS"}
                    </Button>
            </div>
        </Layout>
    )
}

export default Bonus;