import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginSchema } from "../validation/Schema";
import { resetState, userLogin } from "../redux/authSlice";
import { getUserData, resetDashboardState } from "../redux/DashboardSlice";
import Loader from '../images/loader_home.gif';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Modal } from 'react-bootstrap';
import letter from "../images/letter_logo.png";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      acceptedTerms: false, // Adicione o estado do checkbox
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      if (values.acceptedTerms) {
        setLoading(true);
        const body = {
          email: formik.values.email,
        };
        try {
          const data = await dispatch(userLogin(body));
          if (data.payload === "undefined" || !data.payload) return;
          dispatch(getUserData());
          navigate("/home");
        } catch (err) {
          console.log("O email informado não possui acesso.");
        } finally {
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    dispatch(resetState());
    dispatch(resetDashboardState());
  }, [dispatch]);

  return (
    <div className="game-container text-white d-flex align-items-center" style={{ minHeight: '100vh', width: '100vw', flexDirection: 'column' }}>
      <h3 className="mt-5">BEM VINDO AO</h3>
      <center>
        <div className="col-lg-2 col-md-10 col-sm-10 col-7 d-flex justify-content-center align-items-center">
          <iframe src={Loader} allowFullScreen style={{ width: '100%', height: '100%', border: 'none', pointerEvents: 'none', overflow: 'hidden' }} scrolling="no" />
        </div>
      </center>

      <img src={letter} style={{ width: '15rem', marginBottom: '2rem' }} />
      <Form className="mt-1 col-lg-6 col-md-8 col-sm-10 col-11" onSubmit={formik.handleSubmit}>
        <small className="mt-3 mb-2 d-flex text-left">Insira seu e-mail de compra</small>
        <Form.Control
          type="text"
          label="Email"
          plain
          id="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email.toLocaleLowerCase()}
        />
        {formik.touched.email && formik.errors.email ? (
          <p className="text-danger">
            o email {formik.values.email} não possui acesso
          </p>
        ) : null}
        <div className="mt-2 mb-2 d-flex align-items-center">
          <input
            required
            type="checkbox"
            className="me-1"
            checked={formik.values.acceptedTerms}
            onChange={(e) =>
              formik.setFieldValue("acceptedTerms", e.target.checked)
            }
          />
          <label className="text-left">Li e aceito os termos de uso</label>
        </div>
        {loading ? (
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <div className="spinner-border" role="status" style={{ color: '#05d9e8' }}>
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Button className="col-12 mt-2" type="submit" style={{ height: '3.2rem', border: '0.2rem solid #05d9e8', backgroundColor: 'black' }}>
            ACESSAR
          </Button>
        )}
      </Form>
      <div className="mt-4 col-8 separator"></div>
      <div className="mt-3">AINDA NÃO TEM CONTA?</div>
      <Button className="mt-1 col-lg-6 col-md-8 col-sm-10 col-11 text-dark" type="submit" style={{ height: '3.5rem', border: 'none', backgroundColor: '#1afe49', fontWeight: 'bolder' }}>
        <a href="https://checkout.perfectpay.com.br/pay/PPU38CMLSIP" style={{ textDecoration: 'none', color: 'black' }}>
          QUERO ME CADASTRAR
        </a>
      </Button>
      <small onClick={handleModalOpen} style={{ textDecoration: 'underline', marginTop: '0.1rem', cursor: 'pointer' }}>Termos de uso e Política de Privacidade</small>
        <Modal
        show={showModal}
        onHide={handleModalClose}
        centered
        style={{ maxWidth: '90%', marginLeft: '5%' }}
        >
          <Modal.Header
          style={{ 
            background: 'black', 
            borderTop: '2px solid rgba(252, 248, 12, 0.932)',
            borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
            borderRight: '2px solid rgba(252, 248, 12, 0.932)',
            borderBottom: '0 solid black',
            }}
            closeButton
          >
            <h4 className="text-white">
              Política de Privacidade
            </h4>
          </Modal.Header>
          <Modal.Body
          style={{ 
            background: 'black', 
            borderTop: '2px solid rgba(252, 248, 12, 0.932)',
            borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
            borderRight: '2px solid rgba(252, 248, 12, 0.932)',
            borderBottom: '0 solid black',
            color:'white'
            }}
          >
            <p>
            A segurança dos seus dados é de extrema importância para nós do OASISGPT. 
            Estamos totalmente dedicados a salvaguardar a sua privacidade no que se refere a qualquer 
            dado que venhamos a adquirir através do nosso website OASISGPT, bem como em outros websites que 
            estejam sob nossa gestão e controle.
            </p>
            <p>
            Quando necessitamos de informações pessoais, fazemos a solicitação apenas quando 
            estritamente necessário para prestar um serviço apropriado. Nos certificamos de que essa 
            coleta seja realizada de maneira ética, legal e com o seu consentimento expresso. 
            Adicionalmente, sempre esclarecemos os propósitos da coleta e como esses dados serão aplicados.
            </p>
            <p>
            Os dados que coletamos são armazenados somente pelo tempo que é necessário para prestar o serviço 
            solicitado. Todo o armazenamento de informações é protegido com medidas comercialmente aceitáveis para prevenir 
            perdas, roubos, acesso ilícito, divulgação, duplicação, uso ou modificação.
            </p>
            <p>
            Ressaltamos que não divulgamos publicamente informações que possam identificar você pessoalmente a terceiros, a não ser quando requerido por lei.
            </p>
            <p>
            Nosso website pode possuir links para outros websites que não são gerenciados por nós. Apesar disso, salientamos que não temos controle sobre o 
            conteúdo e as políticas desses sites e, portanto, não podemos ser responsabilizados pelas suas políticas de privacidade.
            </p>
            <p>
            Lembramos que você tem o direito de recusar o fornecimento de informações pessoais, mesmo que entenda que tal recusa pode impedi-lo de acessar certos serviços.
            </p>
            <p>
            Ao prosseguir com o uso do nosso site, assumiremos que você concorda com nossas práticas em relação à privacidade e ao gerenciamento de informações pessoais. 
            Se você tiver alguma dúvida sobre como tratamos os dados dos usuários e informações pessoais, sinta-se à vontade para nos contatar.
            </p>
            <ul>
              <li>
              O serviço Google AdSense, utilizado para veicular publicidade, emprega um cookie DoubleClick para exibir anúncios relevantes na Web e limitar o número de vezes que um determinado anúncio é mostrado a você.
              </li>
              <p>
              Para obter mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.
              </p>
              <li>
              Utilizamos anúncios para compensar os custos de operação deste site e financiar futuros desenvolvimentos. Os cookies de publicidade comportamental utilizados neste site foram desenvolvidos para fornecer anúncios 
              mais relevantes, rastreando anonimamente seus interesses e apresentando conteúdos similares que possam ser do seu interesse.
              </li>
              <li>
              Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem verificar se nossos clientes acessaram o site 
              por meio de um dos sites de nossos parceiros, a fim de creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam 
              promoções relacionadas a uma possível compra.
              </li>
            </ul>
          </Modal.Body>
          <Modal.Header
          style={{ 
            background: 'black', 
            borderTop: '2px solid rgba(252, 248, 12, 0.932)',
            borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
            borderRight: '2px solid rgba(252, 248, 12, 0.932)',
            borderBottom: '0 solid black',
            color:'white'
            }}
          >
            <h4>
              Compromisso do Usuário
            </h4>
          </Modal.Header>
          <Modal.Body
          style={{ 
            background: 'black', 
            borderTop: '2px solid rgba(252, 248, 12, 0.932)',
            borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
            borderRight: '2px solid rgba(252, 248, 12, 0.932)',
            borderBottom: '0 solid black',
            color:'white'
            }}
          >
            <p>
            O usuário se compromete a usar de maneira adequada o conteúdo e as informações fornecidas pelo OASISGPT em 
            seu site, estando ciente das seguintes responsabilidades:
            </p>
            <p>A - Evitar o envolvimento em atividades que sejam ilegais, antiéticas ou contrárias à ordem pública;</p>
            <p>B - Abster-se de propagar ou compartilhar publicidade de natureza racista, xenofóbica, ligada a jogos de azar, pornografia ilegal, defesa do terrorismo ou infrações dos direitos humanos;</p>
            <p>C - Impedir a causação de prejuízos aos sistemas físicos (hardware) e lógicos (software) do OASISGPT, seus fornecedores ou de outros, e evitar a disseminação de vírus de computador ou qualquer 
              outro sistema de hardware ou software que possa causar os danos supracitados.
            </p>
          </Modal.Body>
          <Modal.Header
          style={{ 
            background: 'black', 
            borderTop: '2px solid rgba(252, 248, 12, 0.932)',
            borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
            borderRight: '2px solid rgba(252, 248, 12, 0.932)',
            borderBottom: '0 solid black',
            color:'white'
            }}
          >
            <h4>
              Mais informações
            </h4>
          </Modal.Header>
          <Modal.Body
          style={{ 
            background: 'black', 
            borderTop: '2px solid rgba(252, 248, 12, 0.932)',
            borderLeft: '2px solid rgba(252, 248, 12, 0.932)',
            borderRight: '2px solid rgba(252, 248, 12, 0.932)',
            borderBottom: '2px solid rgba(252, 248, 12, 0.932)',
            color:'white'
            }}
          >

            <p>
            Esperamos que tudo esteja claro. Como mencionado anteriormente, caso haja alguma incerteza sobre a necessidade de fornecer certas informações, geralmente é mais seguro manter os cookies ativados ao interagir 
            com os recursos do nosso site.
            Esta política entra em vigor em 01 de janeiro de 2023 às 00:00.
            </p>
            <h5>
              1. Termos
            </h5>
            <p>
            Ao acessar o site OASISGPT, você está concordando em cumprir os termos de serviço estabelecidos, bem como todas as leis e regulamentos aplicáveis. É de sua responsabilidade cumprir todas as 
            leis locais vigentes. Se você não concorda com algum desses termos, não está autorizado a utilizar ou acessar este site. Os materiais presentes neste site são protegidos pelas leis de direitos 
            autorais e marcas comerciais em vigor.
            </p>
            <h5>
              2. Uso de Licença
            </h5>
            <p>
            O site OASISGPT permite que você faça o download temporário de seus materiais (dados ou programas) estritamente para uso pessoal e não comercial, visando apenas visualização. 
            Isto é uma permissão de uso, não um ato de transferência de propriedade. Conforme essa permissão, você está proibido de:
            </p>
            <ol>
              <li>alterar ou reproduzir os materiais;</li>
              <li>empregar os materiais para qualquer objetivo comercial ou para apresentação pública (seja comercial ou não);</li>
              <li>tentar decifrar ou realizar engenharia reversa em qualquer software disponibilizado pelo site OASISGPT;</li>
              <li>eliminar quaisquer marcas de direitos autorais ou outras indicações de propriedade dos materiais; ou</li>
              <li>passar os materiais para outrem ou duplicar os materiais em qualquer outro servidor.</li>
            </ol>
            <p>Essa permissão será revogada automaticamente se você infringir qualquer dessas limitações, e o OASISGPT também pode revogá-la a qualquer instante. Quando encerrar a visualização desses materiais ou 
              quando essa permissão de uso acabar, você deve eliminar todos os materiais baixados que estiverem sob sua guarda, seja em formato digital ou físico.
            </p>
            <h5>3. Isenção de responsabilidade</h5>
            <p>
            Os recursos disponíveis no portal OASISGPT são fornecidos no estado em que se encontram. A plataforma OASISGPT não estende qualquer tipo de garantia, seja explícita ou implícita, e, desta forma, rejeita e desconsidera todas as demais garantias, incluindo, mas não se limitando a, garantias tácitas de venda, adequação para determinado uso ou inexistência de violação de direitos autorais ou outras infrações.
            Além do mais, OASISGPT não assegura ou emite qualquer declaração sobre a exatidão, os possíveis resultados ou a confiabilidade do uso dos materiais em seu portal ou em qualquer outro site associado a este.
            Aposte de maneira consciente! Ao fazer uso do OASISGPT, você é a única pessoa responsável pelos seus lucros e prejuízos. Desaconselhamos que você aplique dinheiro que não possa perder. Adote a gestão de recursos que ensinamos em nosso curso e obtenha bons lucros!
            </p>
            <h5>
              Limitações
            </h5>
            <p>
            O OASISGPT e seus fornecedores não assumirão qualquer responsabilidade por prejuízos (isso inclui, mas não está restrito a, danos pela perda de informações ou ganhos, ou por interrupção de atividades comerciais) que possam surgir devido ao uso ou incapacidade de uso dos recursos disponíveis no OASISGPT, mesmo que o OASISGPT ou um agente autorizado tenha sido notificado, de forma verbal ou escrita, sobre a possibilidade de tais prejuízos. Dependendo da jurisdição, algumas restrições sobre garantias tácitas ou limitações de responsabilidade por danos incidentais ou consequentes podem não ser aplicáveis a você.
            </p>
            <h5>5. Precisão dos materiais</h5>
            <p>
            Os conteúdos apresentados no portal do OASISGPT podem apresentar falhas técnicas, erros de digitação ou imprecisões fotográficas. O OASISGPT não assegura que os dados exibidos em sua plataforma sejam exatos, completo ou recentes. O OASISGPT se reserva o direito de modificar o conteúdo presente em sua plataforma a qualquer instante, sem a necessidade de uma notificação antecipada. Entretanto, o OASISGPT não tem a obrigação de manter os materiais atualizados.
            </p>
            <h5>6. Links</h5>
            <p>
            O OASISGPT não examinou todos os websites associados ao seu próprio site e, portanto, não pode ser responsabilizado pelo conteúdo encontrado em tais páginas externas. A presença de um link em nossa plataforma não sugere que OASISGPT aprove ou recomende esse site. A responsabilidade pelo uso de qualquer site associado recai inteiramente sobre o usuário.
            </p>
            <h5>
              Modificações
            </h5>
            <p>
            O OASISGPT tem a prerrogativa de alterar os termos de serviço deste site quando achar necessário, sem notificação antecipada. Ao utilizar este site, você se compromete a respeitar a versão mais recente destes termos de serviço.
            </p>
            <h5>
              Lei aplicável
            </h5>
            <p>
            Esses regulamentos e diretrizes são estabelecidos e analisados de acordo com os estatutos do OASISGPT, e você aceita de forma incontestável a competência absoluta dos juízos situados nessa região ou localidade.
            </p>
          </Modal.Body>
        </Modal>
      </div>


    )
}

export default Login;