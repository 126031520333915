import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Button } from "react-bootstrap";
import { Card, Row, Col } from "react-bootstrap/";
import Loader from "../images/loader.gif"
import arrowleft from "../images/arrow-left.png";


const externalLink = "https://www.playpix.com/pb/games?openGames=806668-fun&gameNames=Mines"

const FortuneMouse = () => {
    const [loader, setLoader] = useState(false);
    const [messages, setMessages] = useState([]);
    const [minas, setMinas] = useState('');
    const [validoAte, setValidoAte] = useState('');
    const [tentativas, setTentativas] = useState('');
    const [oldMessages, setOldMessages] = useState([]);

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    }
    

    useEffect(() => {
        const socket = new WebSocket('wss://ws-connect-signals-3fc47d5b7c5f.herokuapp.com/')
    
        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            setTimeout(() => {
              setMessages(message);
              setOldMessages(message);
            }, 5000); // 5 segundos de atraso
        };
      }, [messages]);

      useEffect(() => {
        console.log('messages',messages[4])
        console.log('oldMessages', oldMessages)
      },[messages === oldMessages ?'a' : messages])


      useEffect(() => {    
        const formatarLista = (message) => {
          if (message){
            const regexRabbit = /🐭 Fortune Mouse\n/;
            const regexTentativas = /🔥 Nº de Jogadas: (\d+)/;
            const regexValidoAte = /⏱ Válido até: (\d+:\d+)/;
    
            const minasMatch = message.match(regexRabbit);
            const validoAteMatch = message.match(regexValidoAte);
            const tentativasMatch = message.match(regexTentativas);
    
            if (minasMatch) {
              setMinas(minasMatch[0].trim());
            }
    
            if (validoAteMatch) {
              setValidoAte(`Válido até as ${validoAteMatch[1]}`);
            }
    
            if (tentativasMatch) {
              setTentativas(tentativasMatch[1]);
            }
          }
        }
    
        const message = messages[8]; // Assuming messages is defined somewhere
    
        if (message === "expirado") {
          setMinas(null);
          setTentativas(null);
          setValidoAte(null);
        } else {
          formatarLista(message);
        }
    
        setLoader(true);
      }, [messages]);

    return(
        <Layout>
            <div className="game-container">
                <div className="back-button-container">
                    <a href="/games">
                        <img src={arrowleft} className="back-button" />
                    </a>
                </div>
                <Card 
                    className="m-2"
                    style={{background: 'black'}}
                >
                    <Col className="m-2 text-white">
                        {!minas?(<h3 className="piscante">{null}</h3>):<h4 className="mt-1">{minas}</h4>}
                    </Col>
                    {!tentativas
                    ?
                    <>
                        <Card className="d-flex align-items-center justify-content-center bg-black">
                            <div 
                            className="col-lg-6 col-md-8 col-sm-10 col-3 offset-1 iframe-container d-flex justify-content-center align-items-center"
                            style={{
                                flexDirection:'column'
                            }}
                            >
                                <iframe
                                src={Loader}
                                allowFullScreen
                                style={{
                                    width: '160%', 
                                    height: '70%',
                                    display:'absolute',
                                    border: 'none',
                                    pointerEvents: 'none',
                                    overflow: 'hidden'
                                }}
                                scrolling="no"
                                />
                            </div>
                            <h5>
                                Sinal expirado
                            </h5>
                            <h5 className="piscante text-white">
                                Identificando jogada, aguarde...
                            </h5>
                        </Card>
                    </>
                    :
                    <>
                        <Row className="text-white m-2">
                            <Col>
                                {
                                tentativas
                                ?
                                <>
                                    <small>
                                        Nº Máx Jogadas:
                                    </small>
                                    <h6 className="border rounded text-warning">{tentativas}</h6>
                                </>
                                :
                                null
                                }
                            </Col>
                            <Col>
                                {'\n'}
                                {validoAte?
                                <>
                                    <small>
                                        Válido até:
                                    </small>
                                    <h6 className="border rounded text-warning">{validoAte}</h6>
                                </>
                                :
                                null
                                }
                            </Col>
                        </Row>
                    </>
                    }
                </Card>
                <iframe
                    className="rounded border border-2 border-white"
                    title="Site incorporado"
                    src="https://www.acertosbet.com/jogar/4426"
                    width="85%"
                    height="600px"
                    />
            </div>
        </Layout>
    )
}

export default FortuneMouse;