import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import SyncLoader from 'react-spinners/SyncLoader'
import { Card, Row, Col } from "react-bootstrap/";
import Loader from "../images/loader.gif"
import arrowleft from "../images/arrow-left.png";

const FootballStudio = () => {
    const [loader, setLoader] = useState(false);
    const [messages, setMessages] = useState([]);
    const [oldMessages, setOldMessages] = useState([]);

    const [entrada, setEntrada] = useState('');
    const [bacboEntrada, setFootballEntrada] = useState('');
    const [protecao, setProtecao] = useState('');
    const [cobrir, setCobrir] = useState('');
    const [ultimo, setUltimo] = useState('');

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
        // backgroundColor:"green"
    }

    useEffect(() => {
        // const socket = new WebSocket('ws://localhost:8765'); // Substitua pelo seu endpoint WebSocket
        const socket = new WebSocket('wss://ws-connect-signals-3fc47d5b7c5f.herokuapp.com/')
    
        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            // Aqui você pode processar a mensagem recebida e atualizar o estado do componente
            setTimeout(() => {
              setMessages(message);
              setOldMessages(message);
            }, 5000); // 5 segundos de atraso
        };

      }, [messages]);

      useEffect(() => {
        console.log('messages',messages[4])
        console.log('oldMessages', oldMessages)
      },[messages === oldMessages ?'a' : messages])



      useEffect(() => {    
        const formatarLista = (messages) => {
            if (messages) {
                const regexEntrada = /🎯 Entrada Confirmada/;
                const regexBacboEntrada = /🔴 Entrar no Vermelho|🔵 Entrar no Azul/;
                const regexProtecao = /⚪️ Proteção no Empate/;
              
                const entradaMatch = messages.match(regexEntrada);
                const bacboEntradaMatch = messages.match(regexBacboEntrada);
                const protecaoMatch = messages.match(regexProtecao);
              
                if (entradaMatch) {
                  setEntrada(entradaMatch[0]);
                }
              
                if (bacboEntradaMatch) {
                  setFootballEntrada(bacboEntradaMatch[0]);
                }
              
                if (protecaoMatch) {
                  setProtecao(protecaoMatch[0]);
                }
              }
        
        }

        formatarLista(messages[5]);
        if(messages[5] === "expirado"){
            setEntrada(null);
            setFootballEntrada(null);
            setProtecao(null);
        }else{
            formatarLista(messages[5])
        }
        setLoader(true)
    }, [messages]);

    return(
        <Layout>
            <div className="game-container">
                <div className="back-button-container">
                    <a href="/games">
                        <img src={arrowleft} className="back-button" />
                    </a>
                </div>
                <Card 
                    className="mt-2 m-2"
                    style={{
                        // background: 'linear-gradient(to bottom, #0a090c, #2d1545)'
                        background:'black'
                    }}
                >
                    {
                    protecao ? 
                    null
                    :
                    (messages[5] === "expirado" ? 
                    <Card className="mt-2 text-white d-flex align-items-center justify-content-center" style={{ 
                        background:'black'
                    }}>
                        <div 
                        className="col-lg-6 col-md-8 col-sm-10 col-3 offset-1 iframe-container d-flex justify-content-center align-items-center"
                        style={{
                            flexDirection: 'column'
                        }}
                        >
                            <iframe
                            src={Loader}
                            allowFullScreen
                            style={{
                                width: '160%', 
                                height: '70%',
                                display:'absolute',
                                border: 'none',
                                pointerEvents: 'none',
                                overflow: 'hidden'
                            }}
                            scrolling="no"
                            />
                        </div>
                        <h5 className="piscante">
                            Sinal expirado
                        </h5>
                        <h5 className="piscante">
                            Identificando jogada, aguarde...
                        </h5>
                    </Card>:
                    null)
                    }
                    <Row className="text-warning mt-2 m-1">
                        <Col className="rounded">
                            <h6>{entrada}</h6>
                        </Col>
                    </Row>
                    
                    <Row className="m-1">
                        {bacboEntrada ? (
                            <>
                                <Col className="rounded">
                                    <p>
                                        {bacboEntrada?
                                            <>
                                                <h6 className="text-white">entrar em</h6>
                                                <h6 className="col-12 border rounded text-warning">{bacboEntrada}</h6>
                                            </>
                                            :(
                                            <SyncLoader
                                            className="m-3"
                                            cssOverride={override}
                                            color="yellow"
                                            size={5}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                            />
                                        )}
                                    </p>
                                </Col>
                            </>
                            ): (
                                <>
                                {null}
                                
                            </>
                        )}
                    </Row>
                    <Row className="text-warning mt-2 m-1">
                        
                        <Col className="rounded">
                            {protecao?
                            <>
                                <h6 className="text-white">proteções</h6>
                                <h6 className="col-12 border rounded text-warning">{protecao}</h6>
                            </>
                            :
                            (
                                null
                            )}
                        </Col>
                    </Row>
                </Card>
                <iframe
                    className="rounded border border-2 border-white"
                    title="Site incorporado"
                    src="https://www.acertosbet.com/jogar/4000"
                    width="85%"
                    height="600px"
                    />
            </div>
        </Layout>
    )
}

export default FootballStudio;