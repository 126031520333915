import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { AppProvider } from "../context/AppContext";

const Layout = ({ children }) => {
  const [activeButton, setActiveButton] = useState('');

  const handleButtonClick = (href) => {
    setActiveButton(href);
  };
  
  const getCurrentPathname = () => {
    return window.location.pathname;
  };
  
  const isHeaderVisible = getCurrentPathname() !== '/mines' && 
                          getCurrentPathname() !== '/roleta' && 
                          getCurrentPathname() !== '/fortune' &&
                          getCurrentPathname() !== '/fortunerabbit' &&
                          getCurrentPathname() !== "/footballstudio" &&
                          getCurrentPathname() !== "/footballx" &&
                          getCurrentPathname() !== "/bacbo" &&
                          getCurrentPathname() !== "/penaltyshootout" &&
                          getCurrentPathname() !== "/fortunerabbit" &&
                          getCurrentPathname() !== "/immersive" &&
                          getCurrentPathname() !== "/fortunemouse";

  return (
    <>
      <div className="page-layout">
        <div
          className="content"
          style={{ 
            background: 'black',
            width: '100vw',
            marginBottom:'4rem'
          }}
        >
          {isHeaderVisible && <Header/>}
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
