import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from '../actions/authActions';
import logo from '../images/loader.gif';
import sino from '../images/sino.png';
import logout from '../images/logout.png'

const Header = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.dashboard);
    const { email, name, balance } = userData;

    // Extrair apenas o primeiro nome
    const firstName = name?.split(' ')[0];

    const handleLogout = () => {
        // Chame a função de logout
        dispatch(logoutUser());
    };

    return (
        <div className='borderheader'>
            <Row className='m-1 text-white justify-content-center'>
                <Col className=' bg-black mt-2 col-12 d-flex align-items-center'>
                    <img className='col-4 mb-2' src={logo} style={{ width: '5rem' }} />
                    <div style={{ borderRight: '1px solid white', paddingTop: '2rem' }}></div>
                    <p className='col-5 mt-3'>Boas vindas! <br /> {firstName}</p>
                    <img onClick={handleLogout} className='col-1 offset-1' src={sino} style={{ marginLeft: '3.5rem' }} />
                    <a href="/">
                        <img className='col-2' src={logout} style={{ width: '1.5rem', marginLeft: '0.5rem' }} />
                    </a>
                </Col>
            </Row>
        </div>
    )
}

export default Header;
