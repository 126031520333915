import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
// import './App.scss';
import Login from "./pages/Login";
import PlayPix from "./pages/PlayPix";
import Academy from './pages/academy';
import Bonus from './pages/bonus';
import Game from './pages/Game';
import Results from './pages/Results';
import Home from "./pages/Home";
import AviatorRoom from "./games/aviator";
import MinesRoom from "./games/mines";
import Roleta from "./games/roleta";
import FortuneTiger from "./games/fortunetiger";
import FootballStudio from "./games/footballstudio";
import Bacbo from './games/bacbo';
import Immersive from "./games/immersive"
import PrivateRoutes from './routes/PrivateRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import FootballX from "./games/footballX";
import PenaltyShootOut from "./games/penaltyshootout";
import FortuneRabbit from "./games/fortunerabbit";
import FortuneMouse from "./games/fortunemouse";
import Spaceman from "./games/spaceman";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login/>} />
          <Route element={<PrivateRoutes/>}>
            <Route exact path="/home" element={<PlayPix/>} />
            <Route exact path="/game" element={<Home/>} />
            <Route exact path="/bonus" element={<Bonus/>} />

            <Route exact path="/bacbo" element={<Bacbo/>} />
            <Route exact path="/games" element={<Game/>} />
            <Route exact path="/aviator" element={<AviatorRoom/>} />

            <Route exact path="/mines" element={<MinesRoom/>} />
            <Route exact path="/roleta" element={<Roleta/>} />
            <Route exact path="/fortune" element={<FortuneTiger/>} />

            <Route exact path="/footballstudio" element={<FootballStudio/>} />
            <Route exact path="/results" element={<Results/>} />
            <Route exact path="/academy" element={<Academy/>} />
            
            <Route exact path="/immersive" element={<Immersive/>} />
            <Route exact path="/footballx" element={<FootballX/>} />
            <Route exact path="/penaltyshootout" element={<PenaltyShootOut/>} />

            <Route exact path="/fortunerabbit" element={<FortuneRabbit/>} />
            <Route exact path="/fortunemouse" element={<FortuneMouse/>} />
            <Route exact path="/spaceman" element={<Spaceman/>} />
          </Route>
        </Routes>
      </Router>
      <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"/>
    </div>
  );
}

export default App;
