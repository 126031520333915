import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import SyncLoader from 'react-spinners/SyncLoader'
import { Card, Row, Col } from "react-bootstrap/";
import Loader from "../images/loader.gif"
import arrowleft from "../images/arrow-left.png";

const PenaltyShootOut = () => {
    const [loader, setLoader] = useState(false);
    const [messages, setMessages] = useState([]);
    const [validoAte, setValidoAte] = useState('');
    const [tentativas, setTentativas] = useState('');
    const [oldMessages, setOldMessages] = useState([]);
    const [penalty, setPenalty] = useState('');

    useEffect(() => {
        const socket = new WebSocket('wss://ws-connect-signals-3fc47d5b7c5f.herokuapp.com/');
        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            setTimeout(() => {
              setMessages(message);
              setOldMessages(message);
            }, 5000); // 5 segundos de atraso
        };
      }, [messages]);

      useEffect(() => {    
        const formatarMensagem = (message) => {
            if (message) {
              const regexPenalty = /🟢🟢 Entrada Confirmada 🟢🟢[\s\S]+?🎯Entrada:\n([\s\S]+?)\n\n🎲 Tentativas: (\d+)[\s\S]+?⏱ Valido até as (\d+:\d{2})/;
      
              const matches = message.match(regexPenalty);
      
              if (matches) {
                const formattedPenalty = matches[1].replace(/^(.+\|.+)\|(.+\|)$/, '$1\n$2');
                setPenalty(formattedPenalty);
                setTentativas(matches[2]);
                setValidoAte(`Valido até as ${matches[3]}`);
              }
            }
          }
      
          const message = messages[6]; // Assuming messages is defined somewhere
      
          if (message === "expirado") {
            setPenalty(null);
            setTentativas(null);
            setValidoAte(null);
          } else {
            formatarMensagem(message);
          }
      
          setLoader(true);
        }, [messages]);

    return (
    <Layout>
        <div className="game-container">
            <div className="back-button-container">
                <a href="/games">
                    <img src={arrowleft} className="back-button" />
                </a>
            </div>
            <Card 
                className="m-2"
                style={{background: 'black'}}
            >
                <Col className="m-2 text-white">
                {!penalty ? (
                    <h3 className="piscante">{null}</h3>
                    ) : (
                    <pre>
                        <h4 className="mt-1">{penalty}</h4>
                    </pre>
                )}
                </Col>
                {!tentativas
                ?
                <>
                    <Card className="d-flex align-items-center justify-content-center bg-black">
                        <div 
                        className="col-lg-6 col-md-8 col-sm-10 col-3 offset-1 iframe-container d-flex justify-content-center align-items-center"
                        style={{
                            flexDirection:'column'
                        }}
                        >
                            <iframe
                            src={Loader}
                            allowFullScreen
                            style={{
                                width: '160%', 
                                height: '70%',
                                display:'absolute',
                                border: 'none',
                                pointerEvents: 'none',
                                overflow: 'hidden'
                            }}
                            scrolling="no"
                            />
                        </div>
                        <h5>
                            Sinal expirado
                        </h5>
                        <h5 className="piscante text-white">
                            Identificando jogada, aguarde...
                        </h5>
                    </Card>
                </>
                :
                <>
                    <Row className="text-white m-2">
                        <Col>
                            {
                            tentativas
                            ?
                            <>
                                <small>
                                    Nº Máx Jogadas:
                                </small>
                                <h6 className="border rounded text-warning">{tentativas}</h6>
                            </>
                            :
                            null
                            }
                        </Col>
                        <Col>
                            {'\n'}
                            {validoAte?
                            <>
                                <small>
                                    Válido até:
                                </small>
                                <h6 className="border rounded text-warning">{validoAte}</h6>
                            </>
                            :
                            null
                            }
                        </Col>
                    </Row>
                </>
                }
            </Card>
            <iframe
                className="rounded border border-2 border-white"
                title="Site incorporado"
                src="https://www.acertosbet.com/jogar/582"
                width="85%"
                height="600px"
                />
        </div>
    </Layout>
    );
      
}

export default PenaltyShootOut;